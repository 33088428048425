<template>
    <div class="container-fluid pl-0">
        <nl-loader v-if="preloading" :show="true" />
        <div v-else>
            <b-loading v-model="isLoading" :is-full-page="true" />

            <div v-if="!orders.data" class="">
                <b-notification type="is-warning is-light" aria-close-label="Close notification" role="alert">
                    {{ $t('redactor.no-orders') }}
                </b-notification>
            </div>

            <div v-else class="">
                <div v-if="blockTexts.main && blockTexts.main.length" class="mb-4">
                    <div v-html="blockTexts.main" />
                </div>

                <section class="box">
                    <b-tabs v-model="activeTab" @input="getTabData">
                        <b-tab-item
                            v-for="tab in tabs"
                            :key="tab.index"
                            :label="tab.label"
                            :icon="tab.icon"
                            :value="tab.type"
                            :disabled="tab.disabled"
                        >
                            <PartnerOrdersTable
                                :tab-info="tab"
                                @loadAsyncData="loadAsyncData"
                                @goToTab="goToTab"
                                @redactionBulkOrders="redactionBulkOrders"
                                @acceptRedactionBulkOrders="acceptRedactionBulkOrders"
                                @acceptBulkOrders="acceptBulkOrders"
                                @refuseBulkOrders="refuseBulkOrders"
                                @publishBulkOrders="publishBulkOrders"
                                @relaunchCheck="relaunchCheck"
                                @openExportModal="$refs.exportModal.openModal()"
                            />
                        </b-tab-item>
                    </b-tabs>
                </section>
            </div>
        </div>
        <PartnerOrdersExportModal ref="exportModal" url="/partner-orders/export" />

        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import { EventManager } from '@/components/EventManager.js'
    import Toaster from '@/components/UI/Toaster'

    export default {
        name: 'PartnerOrders',
        title: 'partenaire-commandes.title',
        components: {
            EventManager,
            Toaster
        },
        data: function () {
            return {
                site: null,
                tabs: [],
                orders: [],
                blockTexts: [],

                ordersToBeAccepted: [],
                ordersToBeTreated: [],
                ordersTerminate: [],
                ordersTerminateWithErrors: [],
                ordersRefusedCanceled: [],

                statusToBeAccepted: 'a_valider',
                statusToBeTreated: 'en_cours',
                statusTerminate: 'termine',
                statusTerminateWithErrors: 'termine_errors',
                statusCanceled: 'annule',
                statusRefused: 'refuse',

                //UI
                activeTab: 0,
                preloading: false,
                isLoading: false
            }
        },
        created() {
            this.getData()
        },
        mounted: function () {
            if(this.$route.hash === '#erreurs'){
                this.goToTab(4)
            }
        },
        methods: {
            getData: function () {
                this.preloading = true

                var orders = this.axios
                    .get(`/partner-orders`)
                    .then(response => {
                        this.orders = response.data.orders

                        let counts = response.data.counts
                        let countToBeAccepted = counts.find(key => key.statut === this.statusToBeAccepted)
                        let countToBeTreated = counts.find(key => key.statut === this.statusToBeTreated)
                        let countRefused = counts.find(key => key.statut === this.statusRefused)
                        let countCanceled = counts.find(key => key.statut === this.statusCanceled)
                        let countTerminate = response.data.terminate_orders_count
                        let countTerminateWithErrors = response.data.terminate_orders_with_errors_count

                        this.counts = {
                            toBeAccepted: countToBeAccepted ? countToBeAccepted.total : 0,
                            toBeTreated: countToBeTreated ? countToBeTreated.total : 0,
                            refusedCanceled:
                                (countRefused ? countRefused.total : 0) + (countCanceled ? countCanceled.total : 0),
                            terminate: countTerminate ? countTerminate : 0,
                            terminateWithErrors: countTerminateWithErrors ? countTerminateWithErrors : 0
                        }

                        this.blockTexts = response.data.block_texts

                        this.formatTabs()
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })

                Promise.all([orders]).then(() => {
                    this.preloading = false
                })
            },

            refuseBulkOrders(params) {
                this.isLoading = true
                this.axios
                    .patch(`/partner-orders/refuse`, {
                        ids: params.ids,
                        choice: params.choice,
                        motif_refus_autre: params.motif_refus_autre,
                        min_price_url: params.min_price_url,
                        min_price_site: params.min_price_site
                    })
                    .then(response => {
                        if (!response.data.result) {
                            this.snackNotif(this.$t('partenaire-commandes.invalid-edit-orders'), 'is-danger')
                            return
                        }

                        this.snackNotif(this.$t('partenaire-commandes.valid-edit-orders'))

                        this.counts.toBeAccepted = this.counts.toBeAccepted - params.ids.length
                        this.counts.refusedCanceled = this.counts.refusedCanceled + params.ids.length

                        this.orders.data = this.orders.data.map(order => {
                            if (params.ids.includes(order.id)) {
                                order.statut = this.statusRefused
                            }

                            return order
                        })

                        if (this.ordersToBeAccepted.hasOwnProperty('data')) {
                            this.ordersToBeAccepted.data = this.ordersToBeAccepted.data.filter(
                                order => !params.ids.includes(order.id)
                            )
                        }

                        if (this.counts.toBeAccepted < 1 && this.activeTab === 1) {
                            this.activeTab = 0
                        }

                        this.formatTabs()
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            publishBulkOrders(params) {
                this.isLoading = true
                this.axios
                    .patch(`/partner-orders/published`, {
                        ids: params.ids
                    })
                    .then(response => {
                        if (!response.data.result) {
                            this.snackNotif(
                                `${this.$t('partenaire-commandes.invalid-edit-orders')}. ${this.$t(
                                    'partenaire-commandes.verify-date'
                                )}`,
                                'is-danger'
                            )
                            return
                        }

                        if (
                            response.data.result.some(
                                order => order.link_status_message || order.link_second_status_message
                            )
                        ) {
                            this.snackNotif(this.$t('partenaire-commandes.found-errors'), 'is-danger')
                        } else {
                            this.snackNotif(this.$t('partenaire-commandes.valid-edit-orders'))
                        }

                        this.counts.toBeTreated = this.counts.toBeTreated - params.ids.length

                        this.orders.data = this.orders.data.map(order => {
                            if (params.ids.includes(order.id)) {
                                let newOrder = response.data.result.find(o => o.id === order.id)
                                order = newOrder
                            }

                            return order
                        })

                        if (this.ordersToBeTreated.hasOwnProperty('data')) {
                            this.ordersToBeTreated.data = this.ordersToBeTreated.data.map(order => {
                                if (params.ids.includes(order.id)) {
                                    let newOrder = response.data.result.find(o => o.id === order.id)
                                    order = newOrder
                                }

                                return order
                            })
                        }

                        this.formatTabs()
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            acceptBulkOrders(params) {
                this.isLoading = true
                this.axios
                    .patch(`/partner-orders/accept`, {
                        ids: params.ids
                    })
                    .then(response => {
                        if (!response.data.result) {
                            this.snackNotif(this.$t('partenaire-commandes.invalid-edit-orders'), 'is-danger')
                            return
                        }

                        this.snackNotif(this.$t('partenaire-commandes.valid-edit-orders'))

                        this.counts.toBeAccepted = this.counts.toBeAccepted - params.ids.length
                        this.counts.toBeTreated = this.counts.toBeTreated + params.ids.length

                        this.orders.data = this.orders.data.map(order => {
                            if (params.ids.includes(order.id)) {
                                order.statut = this.statusToBeTreated
                            }

                            return order
                        })

                        if (this.ordersToBeAccepted.hasOwnProperty('data')) {
                            this.ordersToBeAccepted.data = this.ordersToBeAccepted.data.filter(
                                order => !params.ids.includes(order.id)
                            )
                        }

                        if (this.counts.toBeAccepted < 1 && this.activeTab === 1) {
                            this.activeTab = 0
                        }

                        this.formatTabs()
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            acceptRedactionBulkOrders(params) {
                this.isLoading = true
                this.axios
                    .patch('/partner-orders/accept-nl', {
                        ids: params.ids,
                        brief: params.brief
                    })
                    .then(response => {
                        if (!response.data.result) {
                            this.snackNotif(this.$t('partenaire-commandes.invalid-edit-orders'), 'is-danger')
                            return
                        }

                        this.snackNotif(this.$t('partenaire-commandes.valid-edit-orders'))

                        this.orders.data = this.orders.data.map(order => {
                            if (params.ids.includes(order.id)) {
                                order.statut = this.statusToBeTreated
                                order.redaction = 'nextlevel'
                                order.brief = params.brief
                            }

                            return order
                        })

                        if (this.ordersToBeAccepted.hasOwnProperty('data')) {
                            this.ordersToBeAccepted.data = this.ordersToBeAccepted.data.filter(
                                order => !params.ids.includes(order.id)
                            )
                        }

                        if (this.counts.toBeAccepted < 1 && this.activeTab === 1) {
                            this.activeTab = 0
                        }

                        if (this.ordersToBeTreated.hasOwnProperty('data')) {
                            this.ordersToBeTreated.data = this.ordersToBeTreated.data.map(order => {
                                if (params.ids.includes(order.id)) {
                                    order.redaction = 'nextlevel'
                                    order.brief = params.brief
                                }

                                return order
                            })
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            redactionBulkOrders(params) {
                this.isLoading = true
                this.axios
                    .patch(`/partner-orders/redaction`, {
                        ids: params.ids,
                        brief: params.brief
                    })
                    .then(response => {
                        if (!response.data.result) {
                            this.snackNotif(this.$t('partenaire-commandes.invalid-edit-orders'), 'is-danger')
                            return
                        }

                        this.snackNotif(this.$t('partenaire-commandes.valid-edit-orders'))

                        this.orders.data = this.orders.data.map(order => {
                            if (params.ids.includes(order.id)) {
                                order.redaction = 'nextlevel'
                                order.brief = params.brief
                            }

                            return order
                        })

                        if (this.ordersToBeTreated.hasOwnProperty('data')) {
                            this.ordersToBeTreated.data = this.ordersToBeTreated.data.map(order => {
                                if (params.ids.includes(order.id)) {
                                    order.redaction = 'nextlevel'
                                    order.brief = params.brief
                                }

                                return order
                            })
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            relaunchCheck(params) {
                let result = {
                    responseCode: true,
                    externalLinks: true,
                    testWordCount: true,
                    firstLink: 'error',
                    secondLink: 'error'
                }

                this.axios
                    .get(`/partner-orders/${params.data.id}/check`)
                    .then(response => {
                        result.responseCode = response.data.result.response_code
                        result.externalLinks = response.data.result.external_links
                        result.testWordCount = response.data.result.test_word_count
                        result.firstLink = response.data.result.first_link
                        result.secondLink = response.data.result.second_link

                        if (result.responseCode === 200 && !result.externalLinks && !result.firstLink) {
                            if (params.data.link_second_url && result.secondLink) {
                                return
                            }

                            this.ordersTerminateWithErrors.data = this.ordersTerminateWithErrors.data.filter(
                                order => order.id !== params.data.id
                            )
                            this.counts.terminateWithErrors = this.counts.terminateWithErrors - 1

                            this.formatTabs()
                        }
                    })
                    .catch(error => console.error(error))
                    .finally(() => params.callback(result))
            },

            exportOrders(params) {
                let link = null

                this.axios
                    .get(`/partner-orders/export?format=${params.format}`)
                    .then(response => {
                        link = response.data.download_link
                    })
                    .catch(error => this.snackNotif(error.response.data.message, 'is-danger'))
                    .finally(() => params.callback(link))
            },

            getTabData(index) {
                switch (index) {
                    case 1:
                        if (this.ordersToBeAccepted.hasOwnProperty('data')) {
                            break
                        }
                        this.isLoading = true

                        this.axios
                            .get('/partner-orders?status=' + this.statusToBeAccepted)
                            .then(response => {
                                this.ordersToBeAccepted = response.data.orders
                                this.formatTabs()
                            })
                            .finally(() => (this.isLoading = false))
                        break
                    case 2:
                        if (this.ordersToBeTreated.hasOwnProperty('data')) {
                            break
                        }
                        this.isLoading = true

                        this.axios
                            .get('/partner-orders?status=' + this.statusToBeTreated)
                            .then(response => {
                                this.ordersToBeTreated = response.data.orders
                                this.formatTabs()
                            })
                            .finally(() => (this.isLoading = false))
                        break
                    case 3:
                        if (this.ordersTerminate.hasOwnProperty('data')) {
                            break
                        }
                        this.isLoading = true

                        this.axios
                            .get('/partner-orders?status=' + this.statusTerminate)
                            .then(response => {
                                this.ordersTerminate = response.data.orders
                                this.formatTabs()
                            })
                            .finally(() => (this.isLoading = false))
                        break
                    case 4:
                        if (this.ordersTerminateWithErrors.hasOwnProperty('data')) {
                            break
                        }
                        this.isLoading = true

                        this.axios
                            .get('/partner-orders?status=' + this.statusTerminateWithErrors)
                            .then(response => {
                                this.ordersTerminateWithErrors = response.data.orders
                                this.formatTabs()
                            })
                            .finally(() => (this.isLoading = false))
                        break
                    case 5:
                        if (this.ordersRefusedCanceled.hasOwnProperty('data')) {
                            break
                        }
                        this.isLoading = true

                        this.axios
                            .get('/partner-orders?status=' + this.statusCanceled)
                            .then(response => {
                                this.ordersRefusedCanceled = response.data.orders
                                this.formatTabs()
                            })
                            .finally(() => (this.isLoading = false))
                        break

                    default:
                        break
                }
            },

            loadAsyncData(data) {
                var field = data.sortField
                var route

                if (data.sortOrder === 'desc') {
                    field = '-' + data.sortField
                }

                var params = {
                    page: data.page,
                    include: data.includes,
                    sort: field
                }

                if (data.filters && data.filters.length > 0) {
                    data.filters.forEach(element => {
                        params[`filter[${element.key}]`] = element.value
                    })
                }

                if (this.activeTab !== 0) {
                    let activeTabStatus = this.tabs.find(tab => tab.index == this.activeTab).status
                    route = data.path + '?status=' + activeTabStatus
                } else {
                    route = data.path
                }

                let perPage = data.per_page ? data.per_page : 20
                perPage = route.includes('status') ? `&per_page=${perPage}` : `?per_page=${perPage}`

                this.axios
                    .get(route + perPage, {
                        params
                    })
                    .then(response => {
                        if (this.activeTab !== 0) {
                            let orderIndex = this.tabs.find(tab => tab.index == this.activeTab).content_key
                            this[orderIndex] = response.data.orders
                        } else {
                            this.orders = response.data.orders
                        }
                        this.formatTabs()
                    })
                    .finally()
            },

            goToTab(index) {
                this.activeTab = index
                this.getTabData(index)
            },

            formatTabs() {
                this.tabs = [
                    {
                        type: 'all',
                        label: this.$t('partenaire-commandes.orderlist.all') + ` (${this.orders.meta.total})`,
                        icon: 'sitemap',
                        content: this.orders,
                        content_key: 'orders',
                        disabled: false,
                        index: 0,
                        status: '',
                        alert: false
                    },
                    {
                        type: 'toBeAccepted',
                        label: this.$t('partenaire-commandes.orderlist.toaccept') + ` (${this.counts.toBeAccepted})`,
                        icon: 'clipboard-list',
                        content: this.ordersToBeAccepted,
                        content_key: 'ordersToBeAccepted',
                        disabled: this.counts.toBeAccepted > 0 ? false : true,
                        index: 1,
                        status: this.statusToBeAccepted,
                        alert: this.blockTexts.to_be_accepted
                    },
                    {
                        type: 'toBeTreated',
                        label: this.$t('partenaire-commandes.orderlist.todealwith') + ` (${this.counts.toBeTreated})`,
                        icon: 'pencil-alt',
                        content: this.ordersToBeTreated,
                        content_key: 'ordersToBeTreated',
                        disabled: this.counts.toBeTreated > 0 ? false : true,
                        index: 2,
                        status: this.statusToBeTreated,
                        alert: this.blockTexts.to_be_treated
                    },
                    {
                        type: 'terminate',
                        label: this.$t('partenaire-commandes.orderlist.finished') + ` (${this.counts.terminate})`,
                        icon: 'clipboard-check',
                        content: this.ordersTerminate,
                        content_key: 'ordersTerminate',
                        disabled: this.counts.terminate > 0 ? false : true,
                        index: 3,
                        status: this.statusTerminate,
                        alert: false
                    },
                    {
                        type: 'terminateWithErrors',
                        label:
                            this.$t('partenaire-commandes.orderlist.finishederror') +
                            ` (${this.counts.terminateWithErrors})`,
                        icon: 'exclamation-circle',
                        content: this.ordersTerminateWithErrors,
                        content_key: 'ordersTerminateWithErrors',
                        disabled: this.counts.terminateWithErrors > 0 ? false : true,
                        index: 4,
                        status: this.statusTerminateWithErrors,
                        alert: this.blockTexts.terminate_with_errors
                    },
                    {
                        type: 'refusedCanceled',
                        label: this.$t('partenaire-commandes.orderlist.denied') + ` (${this.counts.refusedCanceled})`,
                        icon: 'ban',
                        content: this.ordersRefusedCanceled,
                        content_key: 'ordersRefusedCanceled',
                        disabled: this.counts.refusedCanceled > 0 ? false : true,
                        index: 5,
                        status: this.statusRefused,
                        alert: false
                    }
                ]
            }
        }
    }
</script>
